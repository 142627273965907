<template>
  <div id="app">
    <div class="landing-page">
      <header class="header">
        <div class="header-links">
          <a href="https://mcriblocator.com/" class="left-link" target="_blank"
            >McRib Locator</a
          >
          <a href="/about" class="right-link">ABOUT</a>
        </div>
        <div class="header-content">
          <div class="semi-transparent-box-header">
            <h1>McRibIsBack</h1>
            <p>connect with me</p>
          </div>
        </div>
        <img src="../assets/mc_header.png" alt="McRib" class="header-image" />
      </header>

      <footer class="footer">
        <img src="../assets/mc_footer.png" alt="McRib" class="footer-image" />
        <div class="semi-transparent-box-footer">
          <div class="icon-links">
            <a
              href="https://steamcommunity.com/id/McRibIsBack/"
              target="_blank"
              aria-label="Steam"
            >
              <font-awesome-icon :icon="['fab', 'steam']" size="3x" />
            </a>
            <a
              href="https://discord.com/users/387993849441550336"
              target="_blank"
              aria-label="Discord"
            >
              <font-awesome-icon :icon="['fab', 'discord']" size="3x" />
            </a>
            <a
              href="https://bsky.app/profile/mcribisback.io"
              target="_blank"
              aria-label="BlueSky"
            >
              <font-awesome-icon :icon="['fab', 'bluesky']" size="3x" />
            </a>
            <a
              href="https://www.youtube.com/@mcribisback"
              target="_blank"
              aria-label="YouTube"
            >
              <font-awesome-icon :icon="['fab', 'youtube']" size="3x" />
            </a>
            <a
              href="https://www.twitch.tv/mcrib_isback"
              target="_blank"
              aria-label="Twitch"
            >
              <font-awesome-icon :icon="['fab', 'twitch']" size="3x" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="../assets/styles.css"></style>
