import { createApp } from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSteam,
  faDiscord,
  faBluesky,
  faYoutube,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import router from "./router";

library.add(faSteam, faDiscord, faBluesky, faYoutube, faTwitch);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .mount("#app");
