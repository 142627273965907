<template>
  <div id="app">
    <div class="landing-page">
      <header class="header">
        <div class="header-links">
          <a href="https://mcriblocator.com/" class="left-link" target="_blank"
            >McRib Locator</a
          >
          <a href="/" class="right-link">HOME</a>
        </div>
        <div class="header-content">
          <div class="semi-transparent-box-header">
            <h1>About</h1>
          </div>
        </div>
        <img src="../assets/mc_header.png" alt="McRib" class="header-image" />
      </header>

      <footer class="footer">
        <img
          src="../assets/mc_footer.png"
          alt="McRib"
          class="footer-image"
          style="height: 100%"
        />
        <div class="semi-transparent-box-footer">
          <h3>What's Up with Your McRib Obsession?</h3>
          <p>
            I had a friend who passed away suddenly and was obsessed with the
            McRib at a level I've never seen. When the McRib would come back,
            he'd buy at least a dozen and freeze half so he could enjoy a McRib
            even when it wasn't available.
          </p>
          <p>
            My Steam profile was the first place I used the name. I specifically
            wanted McRibIsBack mainly because "TheMcRibIsBack" is just too long.
            When I'm playing games online, people either ask, "Is it really back
            right now?" or say, "The McRib sucks." Usually leads to a positive
            engagment for everyone.
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default { name: "AboutPage" };
</script>

<style src="../assets/styles.css"></style>
